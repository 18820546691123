<template>
  <div class="header-oa-component">
    <div class="header-oa-wrap">
      <!-- <img class="title-logo" src="../assets/img/oa/OA-title.png" alt="" /> -->
      <h5 class="title-h5" @click="goInit">人文社会科学集刊全品种目录</h5>
      <div class="search-input">
        <div class="search-border-wrap">
          <div class="search-select-wrap" v-if="$route.name!='ajA'">
            <el-select ref="selectInput" v-model="value" placeholder="请选择" @change="switchSource(item)"
                       style="width: 115px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div :class="['search-input-wrap', $route.name=='ajA' ? 'search-input-aj' : '']">
            <el-input :placeholder="[$route.name=='ajA' ? '请输入内容搜索稿约' : '请输入内容搜索集刊']" v-model="searchKey" @keyup.enter.native="search">
            </el-input>

            <!-- 请输入搜索内容-->
          </div>
          <el-button class="search-btn" type="warning" @click="search">
            <img src="../assets/img/searchicon.png" alt="" style="width: 29px;" />

          </el-button>
        </div>
      </div>
      <div class="search-wrap">
        <div v-if="$route.name!='ajA' && $route.name!='ajS'" class="go-search" @click="goAllSearch" style="margin-right: 20px;">
          <span>点击查看1217种集刊</span>
          <img src="../assets/img/aj/more-icon.png" alt="" />
        </div>
        <div v-if="$route.name!='ajA'" class="go-search" @click="goSearch">
          <span>点击进入集刊稿约查询</span>
          <img src="../assets/img/aj/more-icon.png" alt="" />
        </div>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: '',
      // OA文章、OA图书、课件、视频、数据集
      options: [
        { label: '全部', value: 1 },
        { label: '集刊名称', value: 2, count: 1, type: 'ajType', sort: null },
        { label: '主办单位', value: 5, count: 1, type: 'ajType', sort: null },
        { label: '出版单位', value: 6, count: 2, type: 'ajType', sort: null },
        // {label: "OA数据集", value: "OA数据集", count: 1, type: "ajType", sort: null},
        // {label: "OA课件", value: "OA课件", count: 1, type: "ajType", sort: null},
        // {
        //     value: 0,
        //     label: '全部'
        // },
        // {
        //     value: 1,
        //     label: 'OA文章'
        // },
        // {
        //     value: 2,
        //     label: 'OA图书'
        // },
        // {
        //     value: 3,
        //     label: '课件'
        // },
        // {
        //     value: 4,
        //     label: '视频'
        // },
        // {
        //     value: 5,
        //     label: '数据集'
        // }
      ],
      value: 1,
    }
  },
  watch: {
    $route: 'init',
  },
  methods: {
    init() {
      this.curSearchKey = this.$route.query.searchKey
      this.searchKey = ''
      if (this.$route.query.searchKey) {
        this.searchKey = this.$route.query.searchKey
      }
      if (this.$route.query.ajType) {
        this.value = parseInt(this.$route.query.ajType)
      }
    },
    goInit () {
      if (this.$route.name == 'academicJournal') {
        return
      } else {
        this.$router.push({ path: '/aj/a'})
      }
    },
    goSearch() {
      // this.$router.push({path: '/ss', query: {pN: this.pageName}})
      var x = ''
      if (this.$route.name == 'academicJournal') {
        x = '人文社会科学集刊全品种目录'
      } else if (this.$route.name == 'ajC') {
        x = '集刊详情页'
      } else if (this.$route.name == 'ajS') {
        x = '集刊目录检索'
      }
      this.$router.push({ path: '/ajA/a',  query: {pN: encodeURI(x)}})
    },
    switchSource(item) {
      //console.log('item', item)
    },
    goAllSearch () {
      var x = ''
      if (this.$route.name == 'academicJournal') {
        x = '人文社会科学集刊全品种目录'
      } else if (this.$route.name == 'ajC') {
        x = '集刊详情页'
      } else if (this.$route.name == 'ajS') {
        x = '集刊目录检索'
      }
      this.$router.push({ path: '/ajS/a',  query: {searchKey:' ', ajType: 1, pN: encodeURI(x)}})
    },
    search() {
      if (this.$route.name == 'ajA') {
        //稿约查询页面 查询
        if (this.curSearchKey == this.searchKey) {
          this.$router.replace({ path: '/ajA/a', query: { searchKey: this.searchKey + ' ', pN: this.$route.query.pN } })
        } else {
          this.curSearchKey == this.searchKey
          this.$router.replace({ path: '/ajA/a', query: { searchKey: this.searchKey, pN: this.$route.query.pN } })
        }
      } else {
        //集刊目录检索
        if (this.curSearchKey == this.searchKey) {
          this.$router.replace({ path: '/ajS/a', query: { searchKey: this.searchKey + ' ', ajType: this.value, pN: this.$route.query.pN } })
        } else {
          this.curSearchKey == this.searchKey
          var x = ''
          if (this.$route.name == 'academicJournal') {
            x = '人文社会科学集刊全品种目录'
          } else if (this.$route.name == 'ajC') {
            x = '集刊详情页'
          }
          if (this.$route.name == 'academicJournal' || this.$route.name == 'ajC') {
            this.$router.push({ path: '/ajS/a', query: { searchKey: this.searchKey, ajType: this.value, pN: encodeURI(x) } })
          } else {
            this.$router.replace({ path: '/ajS/a', query: { searchKey: this.searchKey, ajType: this.value, pN: this.$route.query.pN } })
          }
        }
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.header-oa-component {
  width: 100%;
  height: 400px;
  background: url(../assets/img/aj/AJ-banner.png);
  background-size: 100% 100%;
  float: left;
  color: #fff;
  .header-oa-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-logo {
      width: 937px;
      margin-top: 108px;
    }
    .title-h5 {
      font-size: 67px;
      color: #fff;
      margin-top: 92px;
    }
    .search-input {
      width: 1016px;
      height: 96px;
      padding: 8px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 40px;
      margin-bottom: 12px;
    }
    .search-wrap {
      display: flex;
      .go-search {
        /*width: 280px;*/
        height: 40px;
        line-height: 40px;
        background: rgba(248, 250, 251, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        padding: 0 16px;
        span {
          font-size: 20px;
          color: #c30d23;
          text-decoration: underline;
          font-weight: bold;
          text-underline-offset: 3px;
          cursor: pointer;
        }
        img {
          margin-left: 8px;
          width: 16px;
          justify-self: 16px;
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }


    .search-border-wrap {
      .search-icon {
        font-size: 26px;
        line-height: 0.46rem;
        color: #fff;
        cursor: pointer;
      }
      .search-btn {
        padding: 0 52px;
        border-radius: 0%;
        float: right;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}
</style>
