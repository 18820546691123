<template>
    <div class="news-page">
        <HeaderAnnualMeeting />
        <div style="height: 400px;">
            <HeaderAJ />
        </div>
        <div class="cont">
            <div class="wid1200">
                <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
            </div>
            <div :style="{minHeight:minH}">
                <h5 class="title">
                    <span>{{pageName}}</span>
                </h5>
                <el-row :gutter="20" class="list">
                    <el-col :span="12" class="addborder" v-for="iterm in latestNews" :key="iterm.id">
                        <div class="grid-content bg-purple  clear-fix">
                            <!-- <a v-if="$route.query.id == 'kfhq_kj' && !iterm.mediaPath"
                                style="cursor: auto;">{{iterm.title}}</a>
                            <a v-else @click="golink(iterm)">{{iterm.title}}</a> -->
                            <a v-html="iterm.title" @click="golink(iterm)"></a>
                            <!-- <span>{{iterm.createDate}}</span> -->
                        </div>
                    </el-col>
                </el-row>
                <div class="page_box pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderAJ from '../components/HeaderAJ'
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: {
        HeaderAnnualMeeting,
        HeaderAJ,
        BreadCrumb,
    },
    data() {
        return {
            pN: '',
            pageName: '稿约列表',
            latestNews: [],
            minH: '',
            currentPage: 1,
            pageSize: 20,
            total: 0,
            newsId: '',
        }
    },
    watch: {
        $route: 'init',
    },
    methods: {
        init() {
            this.minH = document.documentElement.clientHeight - 538 + 'px'
            if (this.$route.query.searchKey) {
                this.searchKey = this.$route.query.searchKey
            }
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }
            this.getData()
        },
        getData() {
            let _this = this
            this.$http({
                method: 'post',
                url: '/admin/api/search/find',
                data: {
                    searchKeyword: this.searchKey,
                    oaType: '稿约', // 固定字段
                    resourceType: 'NEWS', // 固定字段
                    pageNo: this.currentPage, //页码
                    pageSize: 20, // 每页条数
                },
            }).then(res => {
                if (res.data.code ==0) {
                    _this.latestNews = res.data.data.rows
                    _this.total = res.data.data.total
                }
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getData()
        },
        golink(item) {
                if (item.jumpUrl) {
                    window.open(item.jumpUrl, '_blank')
                } else if (item.originUrl) {
                    window.open(item.originUrl, '_blank')
                } else {
                    this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
                }
            
        },
    },
    created() {
        this.init()
    },
}
</script>
<style scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.title {
    border-left: 6px solid #bd1a2d;
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #292929;
}
.title > span {
    display: inline-block;
    margin-left: 1px;
    text-indent: 10px;
    border-left: 4px solid #bd1a2d;
    font-size: 20px;
    line-height: 33px;
}
.list .el-col {
    /*line-height: 48px;*/
}
.addborder {
    border-right: 1px solid #e5e5e5;
}
.date {
    color: #606266;
}
.bg-purple {
    line-height: 48px;
    border-bottom: 1px dashed #e6e6e6;
    /*text-align: right;*/
}
.addborder:nth-child(odd) .bg-purple {
    margin-right: 30px;
}
.addborder:nth-child(even) .bg-purple {
    margin-left: 30px;
}
.bg-purple a {
    float: left;
    padding-left: 10px;
    overflow: hidden;
    /*width: 450px;*/
    width: 100%;
    height: 48px;
    /*display: inline-block;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box;
}
.addborder:nth-child(odd) {
    padding-right: 30px;
}
.addborder:nth-child(even) {
    border-right: none;
}
.bg-purple span {
    float: right;
    font-size: 16px;
}
.bg-purple a:hover {
    /* font-weight: bold; */
}
.page_box {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: nowrap;
}
.el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
