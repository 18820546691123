<template>
    <div class="annual-metting-header-component-wrap">
        <div :class="['header-component', $route.name=='am'? 'bigBg' : '']">
            <div class="top-header">
                <div class="img-wrap">
                    <router-link to="/">
                        <img v-if="$route.name=='am'" src="../assets/img/annual-metting/logo1.png" alt="" />
                        <img v-else src="../assets/img/annual-metting/logo2.png" alt="" />

                    </router-link>

                </div>
                <div class="btn-header nav-fr" v-if="!isLogin">
                    <!-- <span class="logon">登录</span>
                <span class="login">注册</span> -->
                    <span class="welcome" style="display: none;">您好，欢迎来到集刊全文数据库！</span>
                    <div class="name person personshort" v-show="!userMsg.isLogined">
                        <a @click="goLogin($event)" style="cursor: pointer;">登录</a> <span>|</span> <a
                            @click="goLogon($event)">注册</a>
                    </div>
                    <div class="name person" v-show="userMsg.isLogined">
                        <div class="login_btn" v-if="userMsg.UserName">
                            <a href="https://vip.ssap.com.cn/vip/member/info?SiteID=12&SourceID=4" class="login-a"
                                :title="userMsg.UserName">{{userMsg.UserName}}</a>|<a :href="userMsg.LoginURL">更换用户</a>|
                            <a :href="userMsg.LogoutURL" @click="outUser(userMsg.LogoutURL,$event)">退出</a>
                        </div>
                        <div class="login_btn" v-else>
                            <a class="login-a" :title="userMsg.BranchInfoName">{{userMsg.BranchInfoName}}(机构)</a>|<a
                                :href="userMsg.LoginURL" @click="changeUser(userMsg.LoginURL, $event)">更换用户</a>
                        </div>
                    </div>
                </div>
                <div class="welcome-header" v-else>
                    <span>您好，</span>欢迎来到集刊全文数据库！
                </div>
                <!-- <div class="top-right-header">
                <span>手机版</span> |
                <span>帮助</span>
            </div> -->
            </div>
            <div class="main-header" v-if="$route.name=='am'">
                <div class="title">
                    <img src="../assets/img/annual-metting/title.png" alt="">
                </div>
                <div class="introduction">
                    <h6>年会介绍</h6>
                    <div>INTRODUCTION</div>
                    <p>{{anObj.summary}}
                    </p>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
export default {
    name: '',
    props: ['annualObj'],
    data() {
        return {
            isLogin: false,
            userMsg: '',
            countNum: 1,
            anObj: {}
        }
    },
    watch: {
        $route: 'init',
        annualObj(newVal, oldVal) {
            this.anObj = newVal
        }
    },
    methods: {
        init() {},
        encodeURI2(){
          var url = encodeURIComponent(window.location.origin + encodeURIComponent(window.location.pathname) + window.location.search + window.location.hash)
          //console.log(url)
          return url;
        },
        goLogin(e) {
            e.preventDefault()
            _trackData.push(['addaction', '顶部导航', '登录按钮'])
            window.location.href = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
        },
        goLogon(e) {
            e.preventDefault()
            _trackData.push(['addaction', '顶部导航', '注册按钮'])
            setTimeout(function() {
                window.location.href = 'https://sso.ssap.com.cn/zas/register?AppID=14&ZAS_referer=' + this.encodeURI2()
            }, 100)
        },
        changeUser(login, e) {
            e.preventDefault()
            _trackData.push(['addaction', '顶部导航', '更换用户按钮'])
            setTimeout(function() {
                window.location.href = login
            }, 100)
        },
        outUser(logOut, e) {
            e.preventDefault()
            // _trackData.push(['addaction', '顶部导航', '退出按钮'])
            setTimeout(function() {
                window.location.href = logOut
            }, 100)
        },
        getUserInfo() {
            let _this = this
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/user/info',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                })
                .then(res => {
                    if (res.data.code == 0) {
                        _this.userMsg = res.data.user
                        // if (_this.userMsg.UserName) {
                        //     _trackData.push(['userset', '用户名', _this.userMsg.UserName])
                        // } else {
                        //     _trackData.push(['userset', '用户名', _this.userMsg.BranchInfoName])
                        // }
                        if (!res.data.user.isLogined) {
                            _this.countNum++
                            let script = document.createElement('script')
                            script.setAttribute('type', 'text/javascript')
                            script.setAttribute('src', 'https://sso.ssap.com.cn/zas/sso.js')
                            script.setAttribute('sso', 'init')
                            script.setAttribute('parment', 'format=js&onlineCallBack=dologin&offlineCallBack=dologout&autoLogin=IP&AppID=14')
                            let heads = document.getElementsByTagName('head')
                            if (heads.length) heads[0].appendChild(script)
                            else document.documentElement.appendChild(script)
                            if (_this.countNum <= 3) {
                                setTimeout(_this.getUserInfo, 500)
                            }
                        }
                    }
                })
        }
    },
    created() {
        this.init()
        this.getUserInfo()
    }
}
</script>

<style lang="scss" scoped>
.header-component {
    min-width: 1400px;
    // min-height: 362px;
    min-height: 100px;
    // background: url(../assets/img/headerbg.jpg);
    background-size: 100% 100%;
    position: relative;
    .top-header {
        width: 1200px;
        height: 100px;
        margin: 0 auto;
        padding-top: 11px;
        line-height: 100px;
        display: flex;
        justify-content: flex-start;
        justify-content: space-between;

        .img-wrap {
            padding-top: 11px;
            img {
                width: 275px; //223px;
                height: 77px;
            }
        }

        .btn-header {
            color: #fff;

            .login {
                margin-left: 22px;
            }
            span {
                cursor: pointer;
            }
        }

        .nav-fr .name {
            display: inline-block;
            margin-left: 4px;
            color: #cd0600;
        }
        .nav-fr .name:before {
            margin-top: 15px;
            content: '';
            float: left;
            width: 26px;
            height: 26px;
        }
        .nav-fr .name.person.personshort {
            width: 130px;
        }
        .nav-fr .name.person {
            // width: 255px;
        }
        // .nav-fr .name.person:before {
        //     background: url('../assets/img/icon_user.png') no-repeat;
        //     background-size: cover;
        // }
        .nav-fr .name.book:before {
            background: url('../assets/img/icon_book.png') no-repeat;
            background-size: cover;
        }
        .nav-fr .name a {
            margin-right: 0;
            font-weight: 500;
            color: #cd0600;
        }
        .nav-fr span.welcome {
            color: #cd0600;
            cursor: auto;
            font-weight: 500;
        }
        .name .login_btn {
            display: flex;
            a.login-a {
                display: block;
                max-width: 220px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .name span {
            margin: 0 5px;
        }

        .welcome-header {
            color: #404040;
            span {
                color: #cd0600;
                cursor: pointer;
            }
        }
        .top-right-header {
            span {
                cursor: pointer;
            }
        }
    }
    .main-header {
        width: 1200px;
        margin: 0 auto;
        .title {
            // padding-top: 230px;
            padding-top: 266px;
            text-align: center;
            margin-bottom: 150px;
            img {
                // width: 907px;
                // height: 248px;
                width: 1008px;
                height: 107px;
            }
        }
        .introduction {
            color: #fff;
            position: absolute;
            bottom: 44px;
            width: 1200px;
            h6 {
                font-size: 32px;
                font-weight: bold;
                line-height: 32px;
                padding-bottom: 39px;
                position: relative;
            }
            h6::after {
                content: '';
                width: 28px;
                height: 6px;
                background: #c30d23;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            div {
                margin-top: 10px;
                margin-bottom: 25px;
                font-size: 24px;
                font-weight: 400;
            }
            p {
                font-family: Source Han Serif CN;
                font-size: 16px;
                font-weight: 500;
                line-height: 23px;
                color: #fff;
            }
        }
    }
}
.bigBg {
    width: 100%;
    height: 960px;
    // height: 1080px;
    background: url(../assets/img/annual-metting/bg-index.jpg);
    background-size: 100% 100%;
    .top-header {
        .nav-fr .name {
            display: inline-block;
            margin-left: 4px;
            color: #fff;
        }
        .nav-fr .name a {
            margin-right: 0;
            font-weight: 500;
            color: #fff;
        }
        .nav-fr span.welcome {
            color: #fff;
            cursor: auto;
            font-weight: 500;
        }
        .welcome-header {
            color: #404040;
            span {
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
</style>
